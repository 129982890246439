import { createTheme } from "@mantine/core";

export const theme = createTheme({
  fontFamily: "Montserrat, sans-serif",
  headings: {
    fontWeight: "700",
    sizes: {
      h2: {
        fontSize: "1.75rem",
      },
    },
  },
});
